/**
 * Generate random uuid
 */
const generateUuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
    const random = (Math.random() * 16) | 0 //  eslint-disable-line no-bitwise
    const str = char === 'x' ? random : (random & 0x3) | 0x8 // eslint-disable-line no-bitwise
    return str.toString(16)
  })

module.exports = { generateUuid }
