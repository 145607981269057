const el = require('../../../lib/utils/jsx_component');
const {
  cross,
  gear,
  pinDropIcon,
  detectedIcon,
  locationPin
} = require('../../../lib/assets/icons');
module.exports = (location, defaultBtn = false, defaultText = '', btnId = '') => {
  // default button
  if (defaultBtn) {
    return el("button", {
      className: "default-btn default rapid-noclick-resp",
      type: "button",
      id: btnId.length ? btnId : ''
    }, el("span", {
      className: "default-btn__label"
    }, defaultText.length ? defaultText : 'Add / Edit'), btnId === 'mAddEditBtnID' ? '' : el("span", {
      className: "configure_location_settings"
    }, el("svg", {
      className: "location-gear",
      viewBox: "0 0 250 200"
    }, el("path", {
      fill: "#687078",
      d: gear
    }))));
  }
  const isActive = location?.isActive || false;
  const isHome = location?.isHome || false;
  const isUHomeLoc = location?.isUHomeLoc || false;
  const homeVisibilityState = isUHomeLoc && isHome;
  if (location?.woeid) {
    return el("button", {
      type: "button",
      className: `location-pill default-btn primary rapid-noclick-resp ${isActive ? 'active' : ''} ${isHome ? 'home' : ''} ${isUHomeLoc ? 'user_home' : ''}`,
      "data-key": location?.woeid,
      "data-photo-woeid": location?.photo_woeid
    }, el("svg", {
      className: `location-form-container__change-location__icon detected-location ${isHome && isUHomeLoc == false ? '' : 'display-hidden'}`,
      height: "13",
      width: "13",
      viewBox: "0 0 14 13"
    }, el("path", {
      d: detectedIcon
    })), el("svg", {
      className: `location-form-container__change-location__icon home-location ${homeVisibilityState ? '' : 'display-hidden'}`,
      height: "11",
      width: "14",
      viewBox: "0 0 11 14"
    }, el("path", {
      d: pinDropIcon
    })), el("span", {
      className: "default-btn__label"
    }, `${location?.display_name}, ${location?.state}`), el("span", {
      className: "default-btn__cross display-hidden",
      "data-ylk": "sec:weather;subsec:local_news;elm:setting;elmt:remove-city;"
    }, el("svg", {
      width: "32",
      height: "22",
      viewBox: "0 0 250 200"
    }, el("path", {
      fill: "#EB0F29",
      d: cross
    }))));
  }
};